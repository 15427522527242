<template>
  <div class="about">
    <div class="titles azjgs">
      <input-list @setData="getData" iptType="1"></input-list>
      <div>
        <span>姓名：</span>
        <el-input placeholder="请输入姓名" v-model="cName" clearable></el-input>
      </div>
      <div>
        <span>用户名：</span>
        <el-input placeholder="请输入用户名" v-model="cLoginname" clearable></el-input>
      </div>
      <div>
        <span>性别：</span>
        <el-select v-model="cGender" placeholder="全部" clearable>
          <el-option label="女" :value="0"></el-option>
          <el-option label="男" :value="1"></el-option>
        </el-select>
      </div>
      <div>
        <span>联系方式：</span>
        <el-input placeholder="请输入联系方式" v-model="cPhone" clearable></el-input>
      </div>
      <div>
        <span>账号状态：</span>
        <el-select v-model="cDelFlag" placeholder="全部" clearable>
          <el-option label="启用" :value="0"></el-option>
          <el-option label="禁用" :value="1"></el-option>
        </el-select>
      </div>
      <span>
        <el-button v-if="$has('viewAdmin')" type="success" size="mini" icon="el-icon-search" @click="searchs(1, pagesize)">查 询</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh-right" @click="reserts">重 置</el-button>
        <el-button v-if="$has('addAdmin')" type="warning" size="mini" icon="el-icon-plus" @click="openDialog(0)">新 增</el-button>
      </span>
    </div>
    <div class="cont">
      <el-table :data="tableData" height="calc(100% - 40px)">
        <el-table-column prop="areaName" label="区域名称" width="101"></el-table-column>
        <el-table-column prop="organizationName" label="所属机构" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="姓名" show-overflow-tooltip></el-table-column>
        <el-table-column prop="loginname" label="用户名"></el-table-column>
        <el-table-column prop="gender" label="性别">
          <template slot-scope="{ row }">
            <el-tag :type="row.gender == 0 ? 'danger' : 'primary'">{{ row.gender == 0 ? '女' : '男' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="联系方式"></el-table-column>
        <el-table-column prop="delFlag" label="账号状态">
          <template slot-scope="{ row }">
            <el-tooltip :disabled="$has('editAdminUseFlag') && row.loginname != $store.state.user.loginname" content="不可禁用自己" placement="top">
              <el-switch v-model="row.delFlag" active-color="#13ce66" :active-value="0" :inactive-value="1" inactive-color="#ff4949" @change="chgSwitch(row)" :disabled="!$has('editAdminUseFlag') || row.loginname == $store.state.user.loginname"></el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="roleName" label="角色"></el-table-column>
        <el-table-column prop="createDt" label="创建时间" width="150"></el-table-column>
        <el-table-column prop="updateDt" label="更新时间" width="150"></el-table-column>
        <el-table-column label="操作" width="240">
          <template slot-scope="{ row }">
            <el-button v-if="$has('editAdmin')" type="primary" size="small" icon="el-icon-edit-outline" @click="openDialog(1, row)">编 辑</el-button>
            <el-button v-if="$has('editAdminPassword')" type="success" size="small" @click="modifyVisible = true" icon="el-icon-unlock">修改密码</el-button>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <img src="../../public/home/noData.svg" width="320" alt="" />
          <span>暂无数据</span>
        </div>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 100, 500, 1000, 5000, 10000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
      <ToTop />
      <!-- 模态框 -->
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :modal="true" :close-on-click-modal="false" v-dialogDrag center @closed="cancles">
        <div class="dialogsC">
          <div class="newDialog">
            <input-list ref="editIpt" @setData="getData" iptType="2" :isAdminDialog="true"></input-list>
            <div>
              <i class="ired">* </i>
              <span>姓名：</span>
              <el-input placeholder="请输入姓名" v-model="paramsObj.name" clearable></el-input>
            </div>
            <div>
              <i class="ired">* </i>
              <span>用户名：</span>
              <el-input placeholder="请输入用户名" v-model="paramsObj.loginname" clearable></el-input>
            </div>
            <div>
              <i class="ired">* </i>
              <span>性别：</span>
              <el-select v-model="paramsObj.gender" placeholder="全部" clearable>
                <el-option label="女" :value="0"></el-option>
                <el-option label="男" :value="1"></el-option>
              </el-select>
            </div>
            <div>
              <i class="ired">* </i>
              <span>联系方式：</span>
              <el-input placeholder="请输入联系方式" v-model="paramsObj.phone" clearable></el-input>
            </div>
            <div>
              <i class="ired">* </i>
              <span>角色类型：</span>
              <el-select filterable v-model="paramsObj.roleId" placeholder="全部" clearable>
                <el-option :label="item.roleName" v-for="(item, index) in roleArr" :key="index" :value="item.id"></el-option>
              </el-select>
            </div>
            <div style="color: red">说明：初始密码为用户名@今年年份，如需修改可登陆后进行密码修改操作</div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancles">取 消</el-button>
          <el-button type="primary" @click="dialogsAdd">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改密码 -->
      <el-dialog title="修改密码" :visible.sync="modifyVisible" width="30%" :modal="true" :close-on-click-modal="false" v-dialogDrag center @closed="cancles">
        <div class="dialogsC">
          <div class="newDialog">
            <div>
              <i class="ired">* </i>
              <span>请输入新密码：</span>
              <el-input show-password placeholder="请输入新密码" v-model="password" clearable></el-input>
            </div>
            <div>
              <i class="ired">* </i>
              <span>请再次输入新密码：</span>
              <el-input show-password placeholder="请再次输入新密码" v-model="passwordEnd" clearable></el-input>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancles">取 消</el-button>
          <el-button type="primary" @click="passwordModify">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { roleQuery, adminQuery, adminSave, adminUpdate, adminChangeUseFlag, adminChangePassword } from '@/util/http'
import inputList from '@/components/inputList/inputList.vue'
import ToTop from '@/components/toTop/toTop.vue'
export default {
  components: { inputList, ToTop },
  data() {
    return {
      currpage: 1,
      pagesize: 10,
      totals: 0,
      tableData: [], //列表
      cgxqy: '', // 查询区域名称
      cjgmc: '', // 查询机构名称
      cName: '', //查询姓名
      cGender: '', //查询性别
      cPhone: '', //查询联系方式
      cLoginname: '', //查询用户名
      cDelFlag: '', //查询标记
      dialogTitle: '新增管理员', //模态框标题
      dialogVisible: false, //模态框状态
      modifyVisible: false, //删除模态框状态
      paramsObj: {}, //模态框字段
      paramsType: '', //新增修改类型
      roleArr: [], //角色数组
      password: '', //新密码
      passwordEnd: '', //再次输入新密码
      user: '', //用户信息
      loadingText: {
        lock: true,
        text: '拼命加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      },
    }
  },
  methods: {
    // 拿值区域和机构
    getData(msg, iptType) {
      if (iptType == 1) {
        this.cgxqy = msg[0]
        this.cjgmc = msg[1]
      } else {
        this.paramsObj.areaId = msg[0]
        this.paramsObj.organizationId = msg[1] || ''
      }
    },
    // 查询
    async searchs(currpage, pagesize) {
      const loading = this.$loading(this.loadingText)
      this.currpage = currpage
      let params = {
        pageNo: currpage,
        pageSize: pagesize,
        areaId: this.cgxqy,
        organizationId: this.cjgmc,
        name: this.cName,
        gender: this.cGender,
        phone: this.cPhone,
        loginname: this.cLoginname,
        delFlag: this.cDelFlag,
      }
      await adminQuery(params)
        .then((data) => {
          if (data) {
            this.tableData = data.result.records
            this.totals = data.result.total
          }
          loading.close()
        })
        .catch(() => {
          loading.close()
        })
    },
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 模态框显示
    openDialog(type, row) {
      this.paramsType = type
      if (type == 0) {
        this.dialogTitle = '新增管理员'
        this.paramsObj.delFlag = 0
        var id = 0
      } else if (type == 1) {
        if (row.areaId) {
          setTimeout(() => {
            // 区域，机构的值回显
            let rs = this.$refs.editIpt
            rs.cgxqy = row.areaId
            rs.cxjgmc().then(() => {
              rs.cjgmc = row.organizationId
              this.paramsObj.organizationId = rs.cjgmc
            })
          }, 500)
        }
        this.paramsObj = Object.assign({}, row)
        this.dialogTitle = '编辑管理员'
        var id = ''
      }
      this.roleList(id)
      this.dialogVisible = true
    },
    // 新增，修改
    async dialogsAdd() {
      if (!this.paramsObj.name) return this.$message.error('姓名不能为空')
      if (!this.paramsObj.loginname) return this.$message.error('用户名不能为空')
      if (!String(this.paramsObj.gender) || this.paramsObj.gender == undefined) return this.$message.error('性别不能为空')
      if (!this.paramsObj.phone) return this.$message.error('联系方式不能为空')
      if (!this.paramsObj.roleId) return this.$message.error('角色类型不能为空')
      let api = this.paramsType == 0 ? adminSave : adminUpdate
      await api(qs.stringify(this.paramsObj)).then((data) => {
        if (data != undefined) this.initData(data)
      })
    },
    // 启用禁用
    chgSwitch(row) {
      const loading = this.$loading(this.loadingText)
      let params = {
        id: row.id,
        delFlag: row.delFlag,
      }
      adminChangeUseFlag(params)
        .then((res) => {
          this.searchs(this.currpage, this.pagesize)
          loading.close()
        })
        .catch(() => {
          loading.close()
        })
    },
    // 取消
    cancles() {
      this.paramsObj = {}
      this.dialogVisible = false
      this.modifyVisible = false
      this.password = ''
      this.passwordEnd = ''
      this.$bus.$emit('clickResert', 2)
    },
    // 重置
    reserts() {
      this.currpage = 1
      this.pagesize = 10
      this.$bus.$emit('clickResert', 1)
      this.cgxqy = this.user.areaId
      this.cjgmc = this.user.organizationId
      this.cName = ''
      this.cGender = ''
      this.cPhone = ''
      this.cLoginname = ''
      this.cDelFlag = ''
      this.searchs(1, 10)
    },
    //角色类型
    roleList(deleted) {
      let params = {
        deleted,
        pageNo: 1,
        pageSize: 1000,
      }
      roleQuery(params).then((data) => {
        this.roleArr = data.result.records
      })
    },
    // 修改密码
    passwordModify() {
      if (!this.password) return this.$message.error('新密码不能为空')
      if (!this.passwordEnd) return this.$message.error('再次输入新密码不能为空')
      if (this.password != this.passwordEnd) return this.$message.error('两次密码输入不一致，请重试！')
      let params = {
        id: this.paramsObj.id,
        password: this.password,
      }
      adminChangePassword(params).then((data) => {
        if (data != undefined) this.initData(data)
      })
    },
    // 弹框清空重查
    initData(data) {
      if (data) this.$message.success(data.message)
      this.cancles()
      this.searchs(1, 10)
    },
  },
  mounted() {
    this.user = JSON.parse(sessionStorage.getItem('user'))
    this.cgxqy = this.user.areaId
    this.cjgmc = this.user.organizationId
    this.searchs(1, 10)
  },
}
</script>
<style src="../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped>
.cont {
  width: 100%;
}
</style>
